class Timer {
    constructor(callback, delay) {
        var timerId, start, remaining = delay;

        this.pause = function () {
            window.clearTimeout(timerId);
            timerId = null;
            remaining -= Date.now() - start;
        };

        this.resume = function () {
            if (timerId) {
                return;
            }

            start = Date.now();
            timerId = window.setTimeout(callback, remaining);
        };

        this.resume();
    }
}
export default Timer
/* Brug således: 

var timer = new Timer(function() {
    alert("Done!");
}, 1000);

timer.pause();
// Do some stuff...
timer.resume();
*/