import {actionCreators as documentActions} from './DocumentStore';
import {actionCreators as windActions} from "./WindStore";
import {actionCreators as storyActions} from "./StoryStore";
export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: (currentStoryId) => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(documentActions.getAll());
        dispatch(windActions.getWind("06183"));
        dispatch(storyActions.getStoriesAndElements(currentStoryId));
    },
    refreshSlow: () => async(dispatch) => {
    }
};
