import React from 'react';
import { connect } from 'react-redux';
import './Icon.css';
import './Element.css';
import config from '../../config.json';



import PropTypes from 'prop-types';

const Icon = ({ id, src, height, coordinates, visible, enabled, flashing, onClick, zIndex } ) => {
    //console.log(`Icon construtor called... Id: ${id}`);

    const iconId = `icon-${id}`;

    // Standard-størrelsen på ikoner 5vh i højde og bredde:
    const heightToWidthRatio = 9/16;
    const standardHeight = 5;

    height = height || standardHeight;
    const width = height * heightToWidthRatio;

    // hvis koord er 0 begge to, så skal ikonet stå i midten
    coordinates = {
        x: coordinates.x || 50 - (width/2) + 3.25,
        y: coordinates.y || 50 - (height/2) - 6.8,
    };

    const iconStyling = {
        left: coordinates.x + 'vw',
        top: coordinates.y + 'vh',
        width: width + 'vw',
        height: height + 'vh'
    }

    iconStyling.zIndex = zIndex || 20;

    const cls = flashing ? 'blink' : '';

    // TODO tilføj evt. highlight klasse til icon-div
    function renderIcon () {
        return ( 
        < div id={iconId} className = {'icon-wrapper '+ cls} style = {iconStyling} onClick={onClick} >
                <img src={`${config.server}${src}`} className="icon-image" alt=''></img>
        </div >
        )
    };

    if (visible || flashing){ return renderIcon()} else return null; 

};

Icon.propTypes = {
    id: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number,
    coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    visible: PropTypes.bool,
    enabled: PropTypes.bool,
    flashing: PropTypes.bool,
    onClick: PropTypes.func
}
export default connect(
    //state => ({state: state}) // lige nu laver den ikke wind-component ud fra state
)(Icon);


