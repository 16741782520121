import React, { Component } from 'react';
import './css/App.css';
import './css/OrstedSans.css';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { actionCreators as routerActions } from './store/RouterActions';
import { actionCreators as windActions } from './store/WindStore';
import Menu from './components/Menu';
import SafetyInduction from './components/safetyinduction/SafetyInduction';



class Home extends Component {

    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    }

    render() {
        return (
            <div className="App">
                <Menu />
                <SafetyInduction />
            </div>
        );
    }
}

export default connect(
    state => ({ state: state }),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        windActions: bindActionCreators(windActions, dispatch)
    })
)(Home);