import React from 'react';
import connect from 'react-redux/es/connect/connect';
import './Label.css';

const Label = ({ id, text, visible, coordinates, height, zIndex } ) => {

    const wrapperStyling = {
        zIndex: zIndex || 10
    }

    // Fordi alle disse ting bliver sat udenfor, vil det også være noget som man kan give med som data
    // man kan evt tilføje en prop som hedder: skal label vises over eller under prik
    const dotSize = 1;
    const aspectRation = 9/16; // height / width af skærm ratio

    const dotStyling = {
        left: coordinates.x + 'vw',
        top: coordinates.y + 'vh',
        height: dotSize + 'vh',
        width: dotSize + 'vh'
    };

    const connectingLineHeight = height//5;
    const connectingLineWidth = 0.3;

    // stregen har nu lavere zIndex for at den dækker for teksen på andre labelbakse
    const connectingLineStyling = {
        top: (coordinates.y - connectingLineHeight) + 'vh',
        left: (coordinates.x + (dotSize * aspectRation) / 2 - ((connectingLineWidth * aspectRation) / 2)) + 'vw',
        height: connectingLineHeight + 'vh',
        borderWidth: connectingLineWidth + 'vh',
        zIndex: 1
    };

    const labelBoxWidth = 100;
    const lineHeight = 2.3;

    const labelBoxStyling = {
        width: labelBoxWidth + 'vw',
        lineHeight: lineHeight + 'vh',
        left: (coordinates.x) - (labelBoxWidth / 2) + 'vw',
        top: (coordinates.y - dotSize - connectingLineHeight - lineHeight) + 'vh',
        zIndex: 2
        
    };

    return !visible ? <></> : (
        <div className='label-wrapper' style={wrapperStyling}>
            <div className='dot' style={dotStyling}></div>
            <div className='connecting-line' style={connectingLineStyling}></div>
            <div className='label-box' style={labelBoxStyling}>
                <div className='label'>
                    {text}
                </div>
            </div>
        </div>
    );
};


export default connect(
    //state => ({state: state}) // lige nu laver den ikke wind-component ud fra state
)(Label);
