import axios from 'axios';
import config from '../config';
import { createAuth } from '../Helpers';
import Timer from '../Timer';
export const reducerName = 'storyState';

const STORY_GET_STORY_REQUEST = 'STORY_GET_STORY_REQUEST';
const STORY_GET_ELEMENTS_REQUEST = 'STORY_GET_ELEMENTS_REQUEST';
const STORY_SET_PLAYING = 'STORY_SET_PLAYING';
const STORY_SET_CURRENTCHAPTER = 'STORY_SET_CURRENTCHAPTER';
const STORY_SET_CURRENT_ELEMENTACTION = 'STORY_SET_CURRENT_ELEMENTACTION';
const STORY_CLEAR_CURRENT_ELEMENTACTIONS = 'STORY_CLEAR_CURRENT_ELEMENTACTIONS';
const STORY_TOGGLE_ELEMENTACTION = 'STORY_TOGGLE_ELEMENTACTION';
const STORY_SET_PROPERTY_ELEMENTACTION = 'STORY_SET_PROPERTY_ELEMENTACTION';
const CHANGE_STORY_ELEMENTACTION = 'CHANGE_STORY_ELEMENTACTION';
const STORY_GET_STORIES_AND_ELEMENTS_RESPONSE = 'STORY_GET_STORIES_AND_ELEMENTS_RESPONSE';

const defaultStoryId = 0;

const initialState = {
    group: "AVV",
    stories: [
        {
            //name: 'Avedøre SafetyInduction',
            //cultureCode: 'da-DK',
            id: 0,
            chapters: [],
            elements: [],
            imagePath: ""
        }
    ],
    elements: [],

    playing: false,
    currentStoryId: defaultStoryId, // hardcode til det danske id
    currentChapter: 0, // array-index
    currentChapterId: 0,
    currentElementActions: {
    },
    loading: { getStories: false, getElements: false, getEvents: false }
};

const clearTimers = () => {
    timers.forEach(t => { t.pause() });
    timers.length = 0;
}
var timers = []
const addTimer = (actionFunc, delay) => {
    var newTimer = new Timer(actionFunc, delay)
    timers.push(newTimer);
}

export const actionCreators = {
    getStoriesAndElements: (currentStoryId) => async (dispatch, getState) => {
        dispatch({ type: STORY_GET_STORY_REQUEST });

        axios({
            method: 'GET',
            url: `${config.server}/api/interactiveStories?group=${initialState.group}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {

            let storyData = response.data;
            if(storyData !== undefined && storyData.length > 0){
                storyData = storyData.map(story => {
                    return {
                        ...story,
                        elements: []
                    }
                });
            }
            // hent kun elementerne, hvis der blev fundet nogen historier
            if(storyData.length > 0){
                dispatch({ type: STORY_GET_ELEMENTS_REQUEST });
    
                axios({
                    method: 'GET',
                    url: `${config.server}/api/elements`,
                    withCredentials: true,
                    auth: createAuth(getState)
                }).then(response => {
                    const elementData = response.data;
    
                    dispatch({type: STORY_GET_STORIES_AND_ELEMENTS_RESPONSE, storyData: storyData, elementData: elementData, currentStoryId: currentStoryId});

                }).catch(error => {
                    console.log('ERROR FETCHING ELEMENTS', error);
                });
            }
     
        }).catch(error => {
            console.log('ERROR FETCHING STORIES', error);
        });
        // TODO HER VAR TESTDATAEN SAT IND
    },
    setPlaying: (value) => async (dispatch, getState) => {
        dispatch({ type: STORY_SET_PLAYING, value });
    },
    setCurrentChapter: (value) => async (dispatch, getState) => {

        dispatch({ type: STORY_SET_CURRENTCHAPTER, value });

    },
    pause: () => async (dispatch, getState) => {
        timers.forEach(t => { t.pause() });
    },
    resume: () => async (dispatch, getState) => {
        timers.forEach(t => { t.resume() });
    },
    initNewChapter: (value) => async (dispatch, getState) => {
        const storyState = getState().storyState;
        //console.log('Init new chapter. Chapter is:', storyState.currentChapter);

        clearTimers();

        storyState.stories.find(story => story.id === storyState.currentStoryId).actions.filter(a => { return a.chapterId && a.chapterId === storyState.currentChapterId }).forEach(action => {
            addTimer((() => {
                const valueInLowerCaseString = String(action.value).toLowerCase();
              
                if(valueInLowerCaseString === "true" || valueInLowerCaseString === "false"){
                    action.value = valueInLowerCaseString === "true";
                }
                dispatch({ type: STORY_SET_CURRENT_ELEMENTACTION, action });
            }),
                action.start * 1000)
        })
    },

    clearChapterActions: (value) => async (dispatch, getState) => {
        //const storyState = getState().storyState;
        //console.log('ClearChapterActions (Clearing timeouts)', storyState.currentChapter);
        clearTimers();
        dispatch({ type: STORY_CLEAR_CURRENT_ELEMENTACTIONS });
    },

    toggleZoomPhotoAction: (elementAction) => async (dispatch) => {
        dispatch({ type: STORY_TOGGLE_ELEMENTACTION, elementAction });
        
        const newAction = {
            sourceElementId: elementAction.sourceElementId,
            property: 'zIndex',
            startProperty: 'startZIndex',
            value: 40,
        };

        dispatch({ type: STORY_SET_PROPERTY_ELEMENTACTION, newAction });
    },

    /* Change an action (component attribute) to either true or false depending on existing value
    i.e. if attribute is declared like flashing=true then result will be like flashing=false */
    toggleElementAction: (elementAction) => async (dispatch) => {
        dispatch({ type: STORY_TOGGLE_ELEMENTACTION, elementAction });
    },

    changeStoryAction: (elementAction) => async (dispatch) => {
        dispatch({ type: CHANGE_STORY_ELEMENTACTION, elementAction });
    },
};

const reducerMethods = {
    STORY_SET_PLAYING: (state, action) => {
        return {
            ...state,
            playing: action.value
        }
    },

    STORY_GET_STORY_REQUEST: (state, action) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                getStories: true
            }
        }
    },

    STORY_GET_STORY_RESPONSE: (state, action) => {
        const stories = action.storyData.length > 0 ? action.storyData : [
            {
                //name: 'Avedøre SafetyInduction',
                //cultureCode: 'da-DK',
                id: 0,
                chapters: [],
                elements: []
            }
        ];

        return {
            ...state,
            stories: stories,
            loading: {
                ...state.loading,
                getStories: false
            }
        }
    },
    
    STORY_GET_ELEMENTS_REQUEST: (state, action) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                getElements: true
            }
        }
    },

    STORY_GET_ELEMENTS_RESPONSE: (state, action) => {
        return {
            ...state,
            elements: action.elementData,
            loading: {
                ...state.loading,
                getElements: false
            }
        }
    },

    STORY_SET_CURRENTCHAPTER: (state, action) => {
        return {
            ...state,
            currentChapter: action.value,
            currentChapterId: state.stories.find(story => story.id === state.currentStoryId).chapters[action.value].id
        }
    },

    STORY_SET_CURRENT_ELEMENTACTION: (state, action) => {
    
        const newState = {
            ...state,
            currentElementActions: {
                ...state.currentElementActions,
                [action.action.destinationElementId]: {
                    ...state.currentElementActions[action.action.destinationElementId],
                    [action.action.property]: action.action.value
                }
            }
        }
        return newState;
    },

    STORY_CLEAR_CURRENT_ELEMENTACTIONS: (state) => {
        return {
            ...state,
            currentElementActions: {}
        }
    },

    STORY_TOGGLE_ELEMENTACTION: (state, action) => {
        var value;
        // hvis denne action ikke er tilføjet til state endnu - så skal den tage den værdi den har fået med (true eller false)
        if (!state.currentElementActions[action.elementAction.destinationElementId]) {
            value = action.elementAction.value
        } else {
            // hvis den allerede ER tilføjet til state - så skal den finde den værdi, som den har inde i state, og så ændre den til det omvendte
            value = !state.currentElementActions[action.elementAction.destinationElementId][action.elementAction.property]
        }

        return {
            ...state,
            currentElementActions: {
                ...state.currentElementActions,
                [action.elementAction.destinationElementId]: {
                    [action.elementAction.property]: value
                }
            }
        }
    },
    /*
    Når man bruger denne skal man ikke bare sende elementAction med, som man gør med de andre
    I stedet skal man lave et nyt obj som hedder newAction, som indeholder følgende props:

       const newAction = {
            sourceElementId: elementAction.sourceElementId, // id'et på det element hvis prop skal ændres
            property: 'zIndex',
            startProperty: 'startZIndex',
            value: 40,
        };
    */
    STORY_SET_PROPERTY_ELEMENTACTION: (state, action) => {
        // find det index som sourceElement ligger på 
        const indexOfSourceElement = state.elements.findIndex(element => {
            return element.id === action.newAction.sourceElementId;
        });

        // startValue sættes først ud fra property'en fordi hvis denne bliver kaldt først gang på dette sourceElement, så er det den værdi der står på property-feltet dens originale værdi
        // denne værdi bliver dog gemt i en ny prop "startProperty" på elementet, efter første gang, fordi den originale værdi som ligger på "property"-feltet bliver overskrevet nu
        // på denne måde kan man finde tilbage til den originale værdi ud fra 'startProperty'-feltet
        // OBS: property'en hedder IKKE "startProperty" men hentes ud fra den nye action som er blevet sendt med - kunne fx være "startZIndex"
        let originalValue = state.elements[indexOfSourceElement][action.newAction.property];

        // jeg tjekker nu om elementet allerede har property'en "startProperty" fordi i så fald ligger den originale værdi gemt der
         const hasStartValueProperty = state.elements[indexOfSourceElement].hasOwnProperty(action.newAction.startProperty);
        // hvis den har startProperty'en som property
        if(hasStartValueProperty){
            // sættes originalValue-variablen til at være startProperty i stedet
            originalValue = state.elements[indexOfSourceElement][action.newAction.startProperty];
        }

        let value;
        // hvis den IKKE indeholder en elementAction med samme id - så skal dens value være det som den får med i actionen originalt
        if (!state.currentElementActions[action.newAction.sourceElementId]) {
            value = action.newAction.value
        } else { // ellers hvis den indeholder en med samme id - så skal value være enten normalt z-index eller fremhævet z-index (så den overlapper zoom-funktion)
            value = state.currentElementActions[action.newAction.sourceElementId][action.newAction.property] === originalValue
                ? action.newAction.value
                : originalValue
        }

        // hvis elementet ikke havde "startProperty" som felt, så skal den sættes, så den ikke bliver overskrevet af den nye værdi
        // og den tilføjes derfor til elementet i state
        if(!hasStartValueProperty){

            const newElements = state.elements.map(element => {
                if(element.id === action.newAction.sourceElementId){
                    return {
                        ...element,
                        [action.newAction.startProperty]: originalValue
                    }
                }
                return element;
            });

            return {
                ...state,
                currentElementActions: {
                    ...state.currentElementActions,
                    [action.newAction.sourceElementId]: {
                        [action.newAction.property]: value,
                        //[action.newAction.startProperty]: originalValue
                    }
                },
                elements: newElements
            }
        } else { // hvis den allerede har "startProperty"'en som property, skal den ikke tilføjes igen, og det er kun "property"'en som opdateres
            return {
                ...state,
                currentElementActions: {
                    ...state.currentElementActions,
                    [action.newAction.sourceElementId]: {
                        [action.newAction.property]: value,
                    }
                }
            }
        }
    },
    CHANGE_STORY_ELEMENTACTION: (state, action) => {
        const newState = {
            ...state,
            playing: false,
            currentStoryId: action.elementAction.storyId,
            currentChapter: 0, // array-index
            currentElementActions: {},
        };
        return newState;
    },
    SET_ELEMENTS_ON_STORY: (state, action) => {
 
        const storiesWithElements = state.stories.map(story => {

            const elements = state.elements.filter(e => e.interactiveStoryIDs.length > 0 && e.interactiveStoryIDs.includes(story.id));
            
            let storyWithElements = {
                ...story,
                elements: elements 
            }

            return storyWithElements;
        });
        // tilføjer dem som property til historien
        const newState = {
            ...state,
            stories: storiesWithElements
        }

        return newState;
    },
    SET_ALWAYS_VISIBLE_PROPERTY: (state, action) => {
        // gennemgå alle stories
        const newStories = state.stories.map(story => {

            // for hver story - lav 
            const elements = story.elements.map(element => {

                let isAlwaysVisible = false;
                // hvis dette element.id findes som destinationElementId på en action på story'en hvor actionType.name === "storyAction", tilføj alwaysVisible: true
                story.actions.forEach(action => {
                    if(action.actionType.name === "storyAction" && action.destinationElementId === element.id){
                        isAlwaysVisible = true;
                    }
                })

                return isAlwaysVisible ? {
                    ...element,
                    visible: isAlwaysVisible
                } : {
                    ...element,
                }
            })

            return {
                ...story, 
                elements: elements
            }
        })

        return {
            ...state,
            stories: newStories
        }
    },
    STORY_GET_STORIES_AND_ELEMENTS_RESPONSE: (state, action) => {

        // først tilføj hentede stories fra api til state
        // hvis der ikke er hentet nogen - 
        if(action.storyData.length === 0){
            return {
                ...state,
                stories: [
                    {
                        //name: 'Avedøre SafetyInduction',
                        //cultureCode: 'da-DK',
                        id: 0,
                        chapters: [],
                        elements: []
                    }
                ],
                loading: {
                    ...state.loading,
                    getStories: false,
                }
            }
        }

        const elements = action.elementData;

        // så sæt elementerne på storiesne
        const storiesWithElements = action.storyData.map(story => {

            const elementsConnectedToStory = elements.filter(e => e.interactiveStoryIDs.length > 0 && e.interactiveStoryIDs.includes(story.id));
            
            let storyWithElements = {
                ...story,
                elements: elementsConnectedToStory 
            }

            return storyWithElements;
        });

        // sæt property'en alwaysVisible på alle elementerne på storiesne
        // hvis et element på en story også findes som destinationelement på en action af typen storyActiob på samme story, så skal den være alwaysVisible = true
        const storiesWithElementsWithAlwaysVisibleProperty = storiesWithElements.map(story => {

            // for hver story - lav 
            const storyElements = story.elements.map(element => {

                let isAlwaysVisible = false;
                // hvis dette element.id findes som destinationElementId på en action på story'en hvor actionType.name === "storyAction", tilføj alwaysVisible: true
                story.actions.forEach(action => {
                    if(action.actionType === 'storyAction' && action.destinationElementId === element.id){
                        isAlwaysVisible = true;
                    }
                })

                return isAlwaysVisible ? { ...element, visible: isAlwaysVisible} : { ...element }
            })

            return {
                ...story, 
                elements: storyElements
            }
        })

        // currentStoryId = enten undefined, 0 eller den historie som brugeren står på 
        let newCurrenStoryId = action.currentStoryId;
        // hvis den er undefined sættes den til defaultStoryId, som er er
        newCurrenStoryId = newCurrenStoryId ? newCurrenStoryId : defaultStoryId;
        // find den story på listen med stories som svarer til det currentID
        const storyWithCurrentId = storiesWithElementsWithAlwaysVisibleProperty.filter(story => story.id == newCurrenStoryId);
        const storyExists = storyWithCurrentId.length >= 0;
        // enten hvis den er 0 eller hvis det currentId (som jo så IKKE er nul, fordi ellers var vi ikke kommet forbi det første tjek i denne if) 
        //      IKKE svarer til et id af de stories, så skal det newCurrenStoryId bare sættes til den første story på listen af de hentede
        if(newCurrenStoryId == 0 || !storyExists){
            newCurrenStoryId = storiesWithElementsWithAlwaysVisibleProperty[0].id
        }

        return {
            ...state,
            currentStoryId: newCurrenStoryId,
            stories: storiesWithElementsWithAlwaysVisibleProperty,
            elements: elements,
            loading: {
                ...state.loading,
                getStories: false,
                getElements: false
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};

