import React from 'react';
import config from '../../config';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as storyActions } from '../../store/StoryStore';
import './SafetyInduction.css';
import Label from './Label';
import ReactPlayer from 'react-player'
import { PlayPauseBtn, ForwardBtn, BackBtn } from './buttons';
import Language from './Language';
import Icon from './Icon';
import CenteredPhoto from './CenteredPhoto.js';
import ZoomPhoto from './ZoomPhoto.js';
import Wind from './Wind.js'


const languageTypes = [
    {
        id: 1,
        language: 'dansk',
        cultureCode: 'da-DK',
        src: '/danish_flag.jpg',
    },
    {
        id: 2,
        language: 'english',
        cultureCode: 'en-GB',
        src: '/british_flag.png',
    },
];

const SafetyInduction = props => {
    const player = useRef(null);

    const storyState = props.storyState;
    const storyActions = props.storyActions;
    const story = props.storyState.stories.find(story => story.id === props.storyState.currentStoryId);
    //const story = props.storyState.stories[props.storyState.currentStoryId];

    const mapImage = story && `${config.server}/${story.imagePath}`;
    const elements = story && story.elements;

    const play = () => {
        //storyActions.resume();
        storyActions.clearChapterActions();
        storyActions.setCurrentChapter(storyState.currentChapter);

        storyActions.initNewChapter();
        player.current.seekTo(0);
        storyActions.setPlaying(true);
    }

    const stop = () => {
        //storyActions.pause();
        storyActions.setPlaying(false);
        storyActions.clearChapterActions();
    }

    const back = () => {
        if (storyState.currentChapter > 0) {
            storyActions.clearChapterActions();
            storyActions.setCurrentChapter(storyState.currentChapter - 1);
            storyActions.initNewChapter();
            storyActions.setPlaying(true);
        }
    }
    const forward = () => {
        if (storyState.currentChapter < story.chapters.length - 1) {
            storyActions.clearChapterActions();
            storyActions.setCurrentChapter(storyState.currentChapter + 1);
            storyActions.initNewChapter();
            storyActions.setPlaying(true);
        }
    }

    const ended = () => {
        console.log('Chapter ended');
        forward();
    }

    const mergeElementsAndActions = (elements, elementActions) => {
        var elementsCopy = [...elements];
        const copy = elementsCopy.map((e) => {
            return {
                ...e,
                elementActions:
                    elementActions[e.id]
            }
        })
        return copy;
    };

    function executeClickAction(elementId) {
        console.log("Now something should happen!!!");

        const actions = story.actions.filter((a) => a.sourceElementId === elementId && a.actionType === 'clickToggle');

        if(actions.length > 0) {
            actions.forEach(action => {
                switch (action.actionType){
                    case 'clickToggle':
                        // hvis destinationselementet er et zoomphoto OG source-elementet er et ikon, så skal ikon-sourceelementet have ændret sit z-index, 
                        //      så den står foran tragten til zoomphoto'et
                        // derfor kaldes toggleZoomPhotoAction, som BÅDE laver den normale toggle OG retter sourceElementets z-index
                        elementIsType(action.destinationElementId, "ZoomPhoto")  && elementIsType(action.sourceElementId, "Icon")
                            ? storyActions.toggleZoomPhotoAction(action)
                            : storyActions.toggleElementAction(action);
                        break;
                    default: 
                    console.log("Unrecognised action");
                }
            });
        }
    }

    function elementIsType(elementId, type){
        const element = elements.filter(e => e.id === elementId);

        if(element !== undefined){
            return element[0].type === type;
        }
        return false;
    }

    function renderElement(e, ClickAction) {
        switch (e.type.toLowerCase()) {
            case 'icon':
                return <Icon
                    key={e.id}
                    id={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    height={e.height}
                    zIndex={e.zIndex}
                    src={e.imagePath} // TODO her går det galt hvis vi bruger den lokale testdata - så skal der i stedet stå filenamr
                    visible={e.visible}
                    onClick={() => executeClickAction(e.id)}
                    {...e.elementActions}
                    />;
            case 'centeredphoto':
                return <CenteredPhoto
                    key={e.id}
                    width={e.width}
                    id={e.id}
                    height={e.height}
                    src={e.imagePath}
                    visible={e.visible}
                    zIndex={e.zIndex}
                    {...e.elementActions}
                    showClose={!storyState.playing} // TODO her er der noget logik ift. showClose som vi måske skal ændre på i v2 - denne logik bliver heller ikke brugt lige nu, da den i CenteredPhoto.js er hardcodet til false
                    storyActions={storyActions} 
                    />;
                    // disse er nu ikke hardcodet inde i CenteredPhoto komponenten
                    //onClick={() => storyActions.toggleElementAction({actionType:'clickToggle', destinationElementId:e.id, property:'visible',value:'false'})}
                    //onClose={() => storyActions.toggleElementAction({actionType:'clickToggle', destinationElementId:e.id, property:'visible',value:'false'})}
            case 'zoomphoto':
                return <ZoomPhoto
                    key={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    id={e.id}
                    src={e.imagePath}
                    visible={e.visible}
                    height={e.height}
                    placementElementId={e.placementElementId}
                    zIndex={e.zIndex}
                    {...e.elementActions} // indeholder de properties og værdier som skrives på action
                    storyActions={storyActions} 
                    />;
            case 'label':
                return <Label
                    key={e.id}
                    coordinates={{ x: e.x, y: e.y }}
                    id={e.id}
                    text={e.text}
                    height={e.height}
                    visible={e.visible}
                    zIndex={e.zIndex}
                    {...e.elementActions} // indeholder de properties og værdier som skrives på action
                    storyActions={storyActions} 
                    />;
            default:
                return;
        }
    }

    // Denne funktion udføres når man trykker på et af sprogene
    function changeLanguage(storyId){

        const isCurrentStory = storyId === story.id;

        if(!isCurrentStory){
            storyActions.changeStoryAction({storyId: storyId});
        }
    }


    function noStoryWithChaptersAvailable(){

        return story
            ? 
                <>
                    <div className='static-map'>
                        <img src={story.imagePath && mapImage} alt="" />
                    </div>
                   
                    {
                        mergeElementsAndActions(
                            elements,
                            storyState.currentElementActions)
                            .map((e) => {
                                return renderElement(e, executeClickAction);
                            })
                    }
                </>
            : 
                <>
                    <div class="center">
                        <h1>
                            Der er endnu ingen interaktive historier som er udgivet
                        </h1>
                    </div>
                </>
    }

    function mainContent() {

        return <>
            <Wind turn={180}></Wind>
            <div className='safety-induction-player'>
                <ReactPlayer
                    width={'70vw'}
                    height={'70vh'}
                    ref={player}
                    className={'video ' +
                    ((story.chapters[storyState.currentChapter].preview ||
                        story.chapters[storyState.currentChapter].path.endsWith('mp4')||
                    story.chapters[storyState.currentChapter].path.endsWith('mov'))?'video-on-top':'')}
                    controls={false}
                    playing={storyState.playing}
                    onEnded={ended}
                    onClickPreview={() => play()}
                    light={(storyState.playing || !story.chapters[storyState.currentChapter].preview)
                        ? false :
                        story.chapters[storyState.currentChapter].preview}
                    url={`${config.server}/${story.chapters[storyState.currentChapter].path}`}
                />
            </div>
         
            <div className='static-map'>
                <img src={mapImage} alt="" />
            </div>
            <div className='induction-controls'>
               
                    <div className='controls-inner-wrapper'>
                        <PlayPauseBtn isPlaying={storyState.playing}
                        onPlay={() => play()}
                        onStop={() => stop()} />

                        <BackBtn enabled={storyState.currentChapter > 0} onClick={back} />
                        <ForwardBtn enabled={storyState.currentChapter <= story.chapters.length - 2} onClick={forward} />
                    </div>
                    <div className='chapter-title'>
                        {(story.chapters[storyState.currentChapter]).name}
                    </div>
              
                
            </div>
            {
                mergeElementsAndActions(
                    elements,
                    storyState.currentElementActions)
                    .map((e) => {
                        return renderElement(e, executeClickAction);
                    })
            }
        </>
    }

    function createLanguageButtons(){
        let languages = [];

        storyState.stories.forEach(story => {
            let language = story.cultureCode && languageTypes.find(language => language.cultureCode === story.cultureCode);
            language && languages.push({...language, storyId: story.id});
        });

        return <>
            <div className="outer-language-wrapper">
                <div className="inner-language-wrapper">
                    {
                        languages.map(language => 
                            <Language
                                key={language.storyId}
                                id={language.id}
                                isCurrent = {story.id === language.storyId}
                                cultureCode = {language.cultureCode}
                                src = {language.src}
                                onClick = {() => changeLanguage(language.storyId)}
                            />
                        )
                    }
                </div>
            </div>
        </>
    }

    return (
        
        <>
            <div className='safety-induction'>
                {(storyState && story && story.chapters && story.chapters.length > 0 && mainContent()) 
                    || noStoryWithChaptersAvailable()
                }

                {createLanguageButtons()}
                
            </div>
        </>
    );
};

export default connect(
    state => ({
        storyState: state.storyState,
    }),
    dispatch => ({
        storyActions: bindActionCreators(storyActions, dispatch)
    })
)(SafetyInduction);
