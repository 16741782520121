import React from 'react';
import Menu from '../components/Menu';
import MapImage from '../images/avedorekort-v5.jpg';
import './StaticMap.css';

export const StaticMap = props => {
    return(
        <div className="App">
            <Menu/>
            <div className='static-map'>
                    <img src={MapImage} alt=""/>
            </div>
        </div>
    ) 
};