import React from 'react';
import Clock from 'react-live-clock';
import { actionCreators as routerActions } from '../store/RouterActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import Wind from './safetyinduction/Wind.js'

import mapImage from '../images/map.png';
import logoImage from '../images/customer_logo.png';

import './Menu.css';

const Menu = (props) => {
    return (
        <div className="menu">
            <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Copenhagen'} interval={30000} />
           {/*<Wind></Wind>*/}

            <div className="menu-point-container">
                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/')}>
                        <img src={require('../images/safety.png')} alt='Hjem menu' />
                    </div>
                </div>
                <div className="menu-point">
                    <div onClick={() => props.routerActions.navigate('/documents')}>
                        <img src={require('../images/documents.png')} alt='Arkiv menu' />
                    </div>
                </div>
                <div className="menu-point" onClick={() => props.routerActions.navigate('/map')}>
                    <img src={mapImage} alt='Map menu' />
                </div>
                <img style={{ position: 'absolute', bottom: 0, left: 0, opacity: 1 }} src={logoImage} alt='' />
            </div>

        </div>
    );
};

export default connect(
    state => state,
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(Menu);