import React from 'react';
import connect from 'react-redux/es/connect/connect';
import './Wind.css';
import windBag from '../../images/cropped_and_rotated_wind_without_white_border.gif';


/* giv med som props: 
speed= 30, 
direction= 20
width = 50
*/

const Wind = props => {

    const wind = {...props.windState.wind};

    // hvis direction er 0 == er det vindstille
    // hvis direction er 360 == er det mod nord
    if(wind.direction === undefined && wind.speed === undefined){
        return(
            <div className='wind-error'>
                Vindretning kunne ikke hentes
            </div>
        )
    }
    if(wind.direction === 0){
        return(
            <div className='wind-error'>
                Vindstille
            </div>
        )
    }

    // TODO hvis det skal være forskelligt fra værk til værk kan man tilføje en prop på wind som hedder noget med degreesToTurn og så kan man tilføje det til directionen

    // fordi den skal drejes 180 grader, så den passer med at kortet vender på hovedet ift. syd og nord
    // 180 fordi animationen peger opad mod nord (men den burde pege mod syd)
    //wind.direction = wind.direction + 180 + 180;

    // hvis pilen peger nord-vest skal teksten ikke stå på hovedet
    // derfor: hvis pilen skal drejes mere end 180 grader
    //      dvs. fra den begynder at dreje sydvest (til og med --> nordvest --> nord) skal teksten dreje den anden vej, så det er læseligt

    const textStyle = {
        fontSize: `${90*(0.02*props.width)}%`,
    }
    
    const wrapperStyle = {
        transform: `rotate(${wind.direction}deg)`,
        width: props.width + '%',
    };

    return(
        <div className='wind' >
            <div className='wind-bag-wrapper' style={wrapperStyle}>
                <img src={windBag} className='wind-bag' alt="icon"></img>
            </div>
            <p className={'speed'} style={textStyle}>{wind.speed} m/s</p>
        </div>
        
    )
};

export default connect(
    state => ({windState: state.windState}) 
)(Wind);
