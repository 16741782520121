import React from 'react';
import './Language.css';
import PropTypes from 'prop-types';


const Language = ({ id, isCurrent, cultureCode, src, onClick}) => {

    const elementId = `elem${id}`;

    function renderLanguage() {
        return (
            < div id={`flag-${elementId}`} className={'flag-wrapper'} onClick={onClick}>
                <img src={src} className={'flag-image ' + (isCurrent === true ? 'isCurrent' : '')}alt=''></img>
            </div >
        )
    };

    return renderLanguage();
};

Language.propTypes = {
    id: PropTypes.number,
    src: PropTypes.string,
    size: PropTypes.number,
}
export default Language;